import React from 'react'
import Seo from '../../components/Seo'
import BigTitle from '../../components/BigTitle'
import DefaultSection from '../../components/DefaultSection'
import TeaserSection from '../../components/TeaserSection'
import TeaserHeader from '../../components/TeaserHeader'
import Article from '../../components/Article'
import NavigationLink from '../../components/NavigationLink'
import CardDeck from '../../components/CardDeck'
import Card from '../../components/Card'
import ContactCard from '../../components/ContactCard'

const Page = () => {
  return (
    <React.Fragment>
      <Seo title="Unternehmen"
           description="BUSCHMAIS ist ein Dresdner IT-Beratungsunternehmen mit Schwerpunkten in der Architekturberatung und der Entwicklung moderner Geschäftsanwendungen."
      />

      <BigTitle title="Unternehmen" className="mask-black" textInverted={true}
                imageRelativePath={'Titelbild_Unternehmen.jpg'}
                menuInverted={true} logoInverted={true}>BUSCHMAIS ist ein Dresdner IT-Beratungsunternehmen, gegründet im
        Jahre 2008. Unsere Schwerpunkte liegen in der Architekturberatung und der Entwicklung moderner
        Geschäftsanwendungen.
      </BigTitle>
      <DefaultSection>
        <Article>
          <h1>Über uns</h1>
          <p>Seit unserer Gründung im Jahre 2008 haben wir unsere Ziele, anspruchsvolle und lösungsorientierte
            Unternehmenssoftware zu entwickeln, Innovation und Technologie zu einem wichtigen Bestandteil unserer Arbeit
            zu
            machen und eine ehrliche und langfristige Zusammenarbeit mit unseren Kunden zu pflegen, konsequent verfolgt.
            Unsere Schwerpunkte liegen in der
            Architekturberatung und der Entwicklung moderner Geschäftsanwendungen.

          </p>

          <p>Unsere Kunden schätzen uns als leidenschaftliche Architekten, Entwickler, Consultants, Trainer, Manager und
            manchmal auch als Feuerlöscher.
            Dabei pflegen wir eine stets offene und ehrliche Kommunikation. Wir haben uns den Forschergeist bewahrt und
            wissen Jahre vor unseren Kunden, was technologisch
            möglich ist.</p>

          <p>Bis heute haben wir umfangreiche, oft auch unsichtbare Softwaresysteme für namhafte Kunden
            erfolgreich umgesetzt. Gemeinsam mit unseren Kunden analysieren, planen und optimieren wir IT-gestützte
            Prozesse und unterstützen
            sie bei der Umsetzung neuer Anforderungen.
            Fokussiert auf ein Projekt blicken wir stets über den Tellerrand und sprechen identifizierte Probleme
            angrenzender Bereiche direkt an.
            Wir legen Wert darauf, unsere Kunden bei Bedarf in der gemeinsamen Konzeptions- und Implementierungsphase zu
            befähigen,
            das Ergebnis der Zusammenarbeit nicht nur zu nutzen, sondern auch eigenständig fortzuführen. </p>

        </Article>
      </DefaultSection>

      <DefaultSection>
        <CardDeck cardsPerRow={2} animateScrollIntoView>
          <Card>
            <div className="text-center text-md-left">
              <p className="subTitle text-muted">Forschung</p>
              <h1>Innovation</h1>

              <p className="mt-5">
                Mit zahlreichen Forschungs- und Open-Source-Projekten leisten wir unseren Beitrag zur Optimierung und
                Digitalisierung von Geschäftsprozessen sowie zur Förderung des IT-Nachwuchses. Immer wieder entstehen
                dabei Lösungen, mit denen wir die Welt von morgen verändern können.
              </p>
              <p className="mt-5">
                <NavigationLink to="innovation/" ariaLabel="Details zu unseren Innovationsprojekten"
                                className="text-black">Details</NavigationLink>
              </p></div>
          </Card>
          <Card>
            <div className="text-center text-md-left">
              <p className="subTitle text-muted">Kooperationen</p>
              <h1>Bildung und Wissenschaft</h1>

              <p className="mt-5">Wir finden, dass vor allem der Förderung des IT-Nachwuchses im Standort Sachsen große
                Bedeutung
                zufällt.
                Gemeinsam mit der Berufsakademie Sachsen, Staatliche Studienakademie Dresden (BA Dresden), der
                Technischen
                Universität
                Dresden sowie der Universität Leipzig können wir in verschiedenen Bereichen auf eine erfolgreiche
                Zusammenarbeit
                blicken. Die Kooperation mit weiteren Hochschulen und ihren spannenden Forschungsthemen liegt uns am
                Herzen.</p>
              <p className="mt-5">
                <NavigationLink to="kooperationen/" ariaLabel="Details unserer Kooperationen mit Hochschulen"
                                className="text-black">Details</NavigationLink>
              </p>
            </div>
          </Card>
        </CardDeck>
        <CardDeck cardsPerRow={2} animateScrollIntoView>
          <Card>
            <div className="text-center text-md-left">
              <p className="subTitle text-muted">Öffentlichkeit</p>
              <h1>Publikationen</h1>

              <p className="mt-5">
                Die Mitarbeiter des BUSCHMAIS Teams sind kontinuierlich in vielfältigen Veröffentlichungen und
                öffentlichen Auftritten präsent, um ihr Fachwissen und ihre Expertise einem breiten Publikum zugänglich
                zu machen. Erfahren Sie mehr über unsere aktive Beteiligung an der Wissensvermittlung und dem fachlichen
                Dialog.
              </p>
              <p className="mt-5">
                <NavigationLink to="publikationen/" ariaLabel="Link zum Archiv unserer Publikationen"
                                className="text-black">Details</NavigationLink>
              </p></div>
          </Card>
          <Card>
            <div className="text-center text-md-left">
              <p className="subTitle text-muted">Engagement</p>
              <h1>Community</h1>

              <p className="mt-5">Als <strong>Fördermitglied des JUG Saxony e. V.</strong> engagieren wir uns intensiv
                seit der ersten Stunde
                für den Verein und blicken auf die großartige Entwicklung einer starken Community. Diese schafft mit
                monatlichen Abendveranstaltungen, welche in unserem Team sehr gern besucht werden, nicht nur Raum für
                das
                Weiterdenken im sächsischen Raum, sondern darf auch stolz auf die Etablierung einer renommierten
                IT-Konferenz, dem JUG Saxony Day, sein. </p>
              <p className="mt-5">
                <NavigationLink to="community" ariaLabel="Details unserer Community-Arbeit"
                                className="text-black">Details</NavigationLink>
              </p>
            </div>
          </Card>
        </CardDeck>
      </DefaultSection>


      <TeaserSection className="bg-brand text-white">
        <TeaserHeader title="Werde Teil des Teams" linkTo={'/karriere/'} linkTitle={'Karriere'}>
          <p className="mb-5">Wir sind stets daran interessiert, Abschlussarbeiten von Studierenden
            zu betreuen. Bereits die gemeinsame Themenfindung gestaltet sich als toller Austausch, bei dem alle Seiten
            neue Blickwinkel und Ansätze kennen lernen können.
            Du verfolgst ein Studium im Informatik-Bereich und hast Lust, Deine Abschlussarbeit unternehmensbegleitend
            zu schreiben? Vielleicht bist Du auch auf der Suche nach einer Werkstudententätigkeit? Wir freuen uns auf
            Deine Ideen!
          </p>
        </TeaserHeader>
      </TeaserSection>

      <DefaultSection>
        <ContactCard />
      </DefaultSection>

    </React.Fragment>
  )
}

export default Page
